/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Card, Section, Container, Columns } from 'react-bulma-components'
import ClientQuote from 'components/client-quote'

// Components
import Separator from 'components/separator'

function HowCanWeHelp({ data }) {
  return (
    <Section className="has-background-grey-white-ter is-small pt-3">
      <Container className="content is-widescreen">        
        <Columns className="is-vcentered is-mobile is-centered">
          <Columns.Column className="is-5-desktop is-4-tablet pr-6 is-hidden-mobile">
          <StaticImage
            src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png?fm=jpg&bg=FAF7F2&w=1200"
            className="pb-0"
          />
          </Columns.Column>
          <Columns.Column className="is-7-desktop is-12-tablet is-12-mobile content ">
            <h2 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile has-text-grey-dark">
              99% of apps fail, let's make yours thrive
            </h2>
            <p className="is-size-4">
              You've got enough on your plate without blown budgets, stressful communications and poor product execution. 
              We help you navigate the risks and create more impact with minimal time investment<span className="has-text-primary is-size-3">*</span>
            </p>
            <div className="has-text-grey"><span className="has-text-primary is-size-4">*</span>On average, our clients meet with us for 30 minutes a week, and their apps typically serve 1-4 million people and generate £1m-£100m in revenue.</div>
            <p className="is-size-4 is-hidden">
              We provide digital capability and leadership to help you develop
              market-leading products that improve your customers' lives using
              mobile, cloud, and AI.
            </p>
            <p className="is-size-4 is-hidden">
              We provide instant digital capability and leadership. working
              alongside you to research, develop and grow market-leading apps
              across mobile and cloud.
            </p>
            <p className="is-size-4 is-hidden">
              We're for businesses that have an ambitious vision, but need more
              software expertise to get there. We'll fill in the gaps and help
              you research, develop and grow a market-leading product using
              mobile, cloud and AI.
            </p>
          </Columns.Column>
        </Columns>
        <Columns className="mt-6">
          <Columns.Column className="is-4-desktop is-6-tablet">
            <Link to="/services/advisory/business-case-help">
              <Card className="classy has-bg-blue is-equal-height">
                <span className="circle small is-top-right" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Planning your app
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3">
                  Avoid costly planning blunders.
                </h3>
                <p className="mt-4">
                  How will you account for hidden app development costs? What
                  are the right technologies? How will you scale? When will you
                  see ROI?
                </p>
                <p><Link className='button is-dark' to="/services/advisory/business-case-help">Learn more</Link></p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-4-desktop is-6-tablet">
            <Link to="/services/advisory/concept-design">
              <Card className="classy has-bg-green is-equal-height">
                <span className="circle small is-top-right" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Concept Viability
                </h2>
                <h3 className="has-text-weight-light is-marginless pb-3 mt-2">
                  Make stuff people will actually use.
                </h3>
                <p className="mt-4 mb-5">
                  How do you ensure customers will engage with your app? What
                  insight will inform your roadmap? How will you measure user
                  satisfaction?
                </p>
                <p><Link className='button is-dark' to="/services/advisory/concept-design">Learn more</Link></p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-4-desktop is-6-tablet">
            <Link to="/services/advisory/growth-diagnostic">
              <Card className="classy has-bg-purple is-equal-height">
                <span className="circle small is-bottom-left" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Growth Hacking
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3 is-6-tablet">
                  Build a growth machine, on autopilot.
                </h3>
                <p className="mt-4">
                  How will you leverage network effects? And increase your
                  K-Factor? How will you retain users? Where should you A/B test
                  to drive revenue?
                </p>
                <p><Link className='button is-dark' to="/services/advisory/growth-diagnostic">Learn more</Link></p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-12-desktop is-12-tablet">
            <Link to="/services/mobile-app-development">
              <Card className="classy has-bg-white is-equal-height">
                <span className="circle small is-bottom-left" />                
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  App Development
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3 is-6-tablet mb-3">
                  Have a capable development team at your side.
                </h3>
                
                <Columns>
                <Columns.Column>
                <h5 className="has-text-weight-light is-marginless mt-2 is-6-tablet">
                  Strategic Support
                </h5>
                <ul>
                  <li>Product Management</li>
                  <li>Service Design</li>
                  <li>Focus Groups &amp; Surveys</li>
                  <li>Accessibility</li>
                  <li>User Testing</li>                  
                </ul>
                </Columns.Column>
                <Columns.Column>
                <h5 className="has-text-weight-light is-marginless mt-2  is-6-tablet">
                  Software Delivery
                </h5>
                <ul>                  
                  <li>Native iOS &amp; Android Apps</li>
                  <li>Web Apps and Portals</li>
                  <li>API's and Integrations</li>
                  <li>Data &amp; Scalability</li>
                  <li>Software Testing</li>
                </ul>
                </Columns.Column>
                <Columns.Column>
                <h5 className="has-text-weight-light is-marginless mt-2  is-6-tablet">
                  User Growth
                </h5>
                <ul>
                  <li>Retention Campaigns</li>
                  <li>Conversion Optimisation</li>
                  <li>Market Research</li>
                  <li>App Store Optimisation</li>
                  <li>A/B Tests &amp; Metrics</li>
                </ul>
                </Columns.Column>
                </Columns>
              </Card>
            </Link>
          </Columns.Column>
        </Columns>
      </Container>
      <Section className=" has-text-right mt-0 pt-6 mt-6">
        <Columns className="is-centered">
          {data.testimonials.edges.slice(0, 2).map(({ node: testimonial }) => (
            <Columns.Column
              key={testimonial.id}
              className="is-5-widescreen is-5-desktop is-6-tablet is-12-mobile has-text-centered"
            >
              <ClientQuote testimonial={testimonial} key={testimonial.id} />
            </Columns.Column>
          ))}
        </Columns>
      </Section>
    </Section>
  )
}

export default HowCanWeHelp
